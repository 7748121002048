import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  private authorization: any;
  private providerData: any;

  constructor(
    private http: HttpClient,
    private clientService: ClientService
  ) { }

  loginSupplier(data) {
    return this.http.post(environment.server + environment.logiSuppliers, data).toPromise();
  }

  public saveAuthorization(data) {
    this.authorization = {
      token: data.authorization.access_token,
      expiresAt: data.authorization.expires_at,
      id: data.id
    };
    localStorage.setItem('tokenSupplier', JSON.stringify(this.authorization));
    this.providerData = this.saveProviderData(data);
  }
  private saveProviderData(data): any {
    return {
      company_name: data.company_name,
      contact_name: data.contact_name,
      email: data.email,
      phone: data.phone,
      business_activity: data.business_activity
    };
  }

  public isValidToken() {
    const token = this.getToken();
    if (!token) { return false; }
    const expiresAt = new Date(token.expiresAt);
    const today = new Date();
    return expiresAt > today;
  }

  private getToken(): any {
    if (this.authorization) {
      return this.authorization;
    } else {
      const tokenInLocalStorage = localStorage.getItem('tokenSupplier');
      if (tokenInLocalStorage) {
        return this.authorization = JSON.parse(tokenInLocalStorage);
      } else {
        return null;
      }
    }
  }

  async getProviderData(): Promise<any> {
    if (!this.providerData) {
      this.providerData = await this.clientService.getSupplier(this.authorization.id);
    }
    return this.providerData;
  }
}
