import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  public durationInSeconds = 5;

  constructor(
    private snackBar: MatSnackBar) { }


  openSnackBar(message?, action?) {
    this.snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.hasOwnProperty('error')) {
            const err = error.error;
            if (err.hasOwnProperty('errors')) {
              this.openSnackBar(err.message);
            } else if (err.hasOwnProperty('message')) {
              this.openSnackBar(err.message);
            } else {
              this.openSnackBar(err.message);
            }
          }
        }
        return throwError(error);
      })
    );
  }

}
