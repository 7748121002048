import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Project } from '@interfaces/project';
import { Supplier } from '@interfaces/supplier';
import { Brand } from '@interfaces/brand';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient
  ) {
  }

  public finishBudget = false;

  buildFormData(formData: any, data: any, parentKey: any) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  jsonToFormData(data: any) {
    const formData = new FormData();
    this.buildFormData(formData, data, null);
    return formData;
  }

  async postClient(data): Promise<any> {
    const formData = this.jsonToFormData(data);
    await this.http.post(environment.server + environment.post_clients, formData).toPromise();
  }

  postBrands(id: number, brands: Array<Brand>): Promise<any> {
    const promises = [];
    brands.forEach((brand) => {
      promises.push(this.http.post(environment.server + environment.create_brands(id), brand).toPromise());
    });
    return Promise.all(promises);
  }

  async postProject(data: Project, id: number): Promise<any> {
    await this.http.post(environment.server + environment.post_project(id), data).toPromise();
  }

  getQuotation(quotationId: number) {
    return this.http.get(environment.server + environment.updateQuotation(quotationId)).toPromise();
  }

  updatePriority(data, quotationId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.updatePriority(quotationId), formData).toPromise();
  }

  updateQuotation(data, quotationId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.updateQuotation(quotationId), formData).toPromise();
  }

  postSuppliers(data: Supplier) {
    return this.http.post(environment.server + environment.suppliers, data).toPromise();
  }

  postDirectExpense(data, projectId: number) {
    return this.http.post(environment.server + environment.quotations_directExpense(projectId), data).toPromise();
  }

  postQuotationS1(data, projectId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.post(environment.server + environment.quotations_s1(projectId), formData).toPromise();
  }
  putQuotationS1(data, quotationId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.update_quotations_s1(quotationId), formData).toPromise();
  }

  postQuotationS2(data, quotationId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.post(environment.server + environment.quotations_s2(quotationId), formData).toPromise();
  }
  putQuotationS2(data, quotationId: number, supplierId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.update_quotations_s2(quotationId, supplierId), formData).toPromise();
  }
  deleteQuotationS2(quotationId: number, supplierId: number) {
    return this.http.delete(environment.server + environment.update_quotations_s2(quotationId, supplierId)).toPromise();
  }

  postQuotationS3(data, quotationId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.post(environment.server + environment.quotations_s3(quotationId), formData).toPromise();
  }
  putQuotationS3(data, quotationId: number, supplierId: number) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.update_quotations_s3(quotationId, supplierId), formData).toPromise();
  }
  deleteQuotationS3(quotationId: number, supplierId: number) {
    return this.http.delete(environment.server + environment.update_quotations_s3(quotationId, supplierId)).toPromise();
  }

  getQuotationS4(quotationId: number): Promise<any> {
    return this.http.get(environment.server + environment.quotations_s4(quotationId)).toPromise();
  }
  postQuotationS4(data, quotationId: number) {
    return this.http.post(environment.server + environment.postQuotations_s4(quotationId), data).toPromise();
  }

  rejectQuotation(data, quotationId: number) {
    return this.http.post(environment.server + environment.rejectQuotation(quotationId), data).toPromise();
  }

  getQuotationsByProject(quotationId: number) {
    return this.http.get(environment.server + environment.quotations_s1(quotationId)).toPromise();
  }

  getbusinessActivities() {
    return this.http.get(environment.server + environment.business_activities).toPromise();
  }

  getAllQuotations() {
    return this.http.get(environment.server + environment.allQuotations).toPromise();
  }

  updateUser(userId: number, data) {
    return this.http.put(environment.server + environment.updateUserProfile(userId), data).toPromise();
  }

  postExpenses(data) {
    const formData = this.jsonToFormData(data);
    return this.http.post(environment.server + environment.post_expenses, formData).toPromise();
  }
  putExpenses(data, supplyId, expenseId) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.put_expenses(supplyId, expenseId), formData).toPromise();
  }
  deleteExpenses(supplyId, expenseId) {
    return this.http.delete(environment.server + environment.delete_expenses(supplyId, expenseId)).toPromise();
  }
  getResumenExpenses(supplyId) {
    return this.http.get(environment.server + environment.resumen_expenses(supplyId)).toPromise();
  }
  confirmExpenses(data, supplyId) {
    return this.http.post(environment.server + environment.confirm_expenses(supplyId), data).toPromise();
  }

  postExpensesRefound(data) {
    const formData = this.jsonToFormData(data);
    return this.http.post(environment.server + environment.post_expenses_refound, formData).toPromise();
  }
  putExpensesRefound(data, supplyId, expenseId) {
    const formData = this.jsonToFormData(data);
    return this.http.put(environment.server + environment.put_expenses_refound(supplyId, expenseId), formData).toPromise();
  }
  deleteExpensesRefound(supplyId, expenseId) {
    return this.http.delete(environment.server + environment.delete_expenses_refound(supplyId, expenseId)).toPromise();
  }
  getResumenExpensesRefound(supplyId) {
    return this.http.get(environment.server + environment.resumen_expenses_refound(supplyId)).toPromise();
  }
  confirmExpensesRefound(data, supplyId) {
    return this.http.post(environment.server + environment.confirm_expenses_refound(supplyId), data).toPromise();
  }
  confirmInformation(data, id, type) {
    switch (type) {
      case 'create_budget':
        return this.postQuotationS4(data, id);
      case 'create_internal_spending':
        return this.confirmExpenses(data, id);
      case 'create_refound':
        return this.confirmExpensesRefound(data, id);
    }
  }
  getSchedules() {
    return this.http.get(environment.server + environment.calendar).toPromise();
  }
  deleteProject(projectId) {
    return this.http.delete(environment.server + environment.projects + '/' + projectId).toPromise();
  }
  updateProject(data, projectId) {
    return this.http.put(environment.server + environment.projects + '/' + projectId, data).toPromise();
  }

  deleteClient(clientId) {
    return this.http.delete(environment.server + environment.clients + '/' + clientId).toPromise();
  }

  updateClient(data, clientId) {
    return this.http.put(environment.server + environment.clients + '/' + clientId, data).toPromise();
  }

  deleteElemento(elementoId) {
    return this.http.delete(environment.server + environment.elementos + '/' + elementoId).toPromise();
  }

  getUserElemento(elementoId) {
    return this.http.get(environment.server + environment.elementos + '/' + elementoId).toPromise();
  }

  updateElemento(elementoId, data) {
    return this.http.put(environment.server + environment.elementos + '/' + elementoId, data).toPromise();
  }

  getListAdmin() {
    return this.http.get(environment.server + environment.admin);
  }

  sendRhForm(quotationId: number, data) {
    const formData = this.jsonToFormData(data);
    const url = environment.server + environment.rh;
    return this.http.post(url + '/' + quotationId, formData);
  }

  sendProviderForm(providerId: any, data) {
    const formData = this.jsonToFormData(data);
    const url = environment.server + environment.provider;
    return this.http.post(url + '/' + providerId, formData);
  }

  sendCommentByQuotation(quotationId: number, data) {
    const url = environment.server + environment.comments.replace(
      '{quotation_id}',
      quotationId.toString()
    );
    return this.http.post<any>(url, data);
  }


  sendStatusProvider(data: any) {
    const url = environment.server + environment.status;
    return this.http.post<any>(url, data);
  }

}
