import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UsersService } from '@services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-choose-format',
  templateUrl: './pay-files.component.html',
  styleUrls: ['../create-client/create-client.component.scss', './pay-files.component.scss']
})
export class PayFilesComponent implements OnInit {

  public providerForm: FormGroup;
  public isSubmittedProvider = false;
  public payFile = '';
  public infoProvider: any;
  public hasInfo = false;

  constructor(
    private fb: FormBuilder,
    public usersService: UsersService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PayFilesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.providerForm = this.fb.group({
      message: [''],
      invoice_paid_number: [''],
      invoice_paid_date: [''],
      invoice_paid_file: [''],
      invoice_paid_voucher_file: [''],
    });
  }

  ngOnInit() {
    if (this.data.provider_id) {
      this.pathFormProvider(this.data);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveProvider(provider?: any) {
    this.isSubmittedProvider = true;
    const data = this.providerForm.value;
    let date = this.providerForm.value.invoice_paid_date;
    date = moment(date).format('DD/MM/YYYY');
    data.invoice_paid_date = date;
    this.usersService.sendProviderForm(this.infoProvider.provider_id, data).subscribe(
      response => {
        this.openSnackBar('Infomación actualizada');
        this.dialogRef.close();
        location.reload();
      }, err => {
      }
    );
  }

  pathFormProvider(info) {
    this.infoProvider = info;
    if (
      this.infoProvider.invoice_paid_number ||
      this.infoProvider.invoice_paid_date ||
      this.infoProvider.invoice_paid_file ||
      this.infoProvider.invoice_paid_voucher_file) {
        this.hasInfo = true;
    }
    if (info.invoice_paid_date !== null) {
      const str = info.invoice_paid_date;
      const darr = str.split('/');
      const ISOFormat = new Date(parseInt(darr[2], 10), parseInt(darr[1], 10) - 1, parseInt(darr[0], 10));
      this.providerForm.patchValue({
        invoice_paid_date: ISOFormat.toISOString()
      });
    }
    if (info.message.length > 0) {
      this.providerForm.patchValue({
        message: info.message[info.message.length - 1].message,
      });
    }
    this.providerForm.patchValue({
      invoice_paid_number: info.invoice_paid_number,
      invoice_paid_file: info.invoice_paid_file,
      invoice_paid_voucher_file: info.invoice_paid_voucher_file,
    });
  }

  openSnackBar(message?: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

}
