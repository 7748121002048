import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProtectedComponent } from './pages/protected/protected.component';
import { NavbarComponent } from '@components/navbar/navbar.component';
import { LayoutComponent } from '@components/layout/layout.component';
import { NewProjectComponent } from '@components/dialog/new-project/new-project.component';
import { CreateClientComponent } from '@components/dialog/create-client/create-client.component';
import { ConfirmInformationComponent } from '@components/dialog/confirm-information/confirm-information.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderComponent } from '@components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NewProviderComponent } from '@components/dialog/new-provider/new-provider.component';
import { ChooseFormatComponent } from '@components/dialog/choose-format/choose-format.component';
import { ConfirmationDeleteComponent } from '@components/dialog/confirmation-delete/confirmation-delete.component';
import { SupplierModule } from '../app/pages/supplier/supplier.module';
import { InputFilesModule } from '@components/input-files/input-files.module';
import { ConfirmDepartureComponent } from '@components/dialog/confirm-departure/confirm-departure.component';
import { PayFilesComponent } from '@components/dialog/pay-files/pay-files.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommentsComponent } from '@components/dialog/comments/comments.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ProtectedComponent,
    LayoutComponent,
    CreateClientComponent,
    ConfirmInformationComponent,
    NewProjectComponent,
    LoaderComponent,
    NewProviderComponent,
    ChooseFormatComponent,
    ConfirmationDeleteComponent,
    ConfirmDepartureComponent,
    PayFilesComponent,
    CommentsComponent
  ],
  imports: [
    InputFilesModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    SupplierModule,
    MatDatepickerModule,
    CommonModule,
  ],
  entryComponents: [
    CreateClientComponent,
    ConfirmInformationComponent,
    NewProjectComponent,
    NewProviderComponent,
    ChooseFormatComponent,
    ConfirmationDeleteComponent,
    ConfirmDepartureComponent,
    PayFilesComponent,
    CommentsComponent
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
