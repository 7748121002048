import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from '@interfaces/client';
import { ClientService } from '@services/client.service';
import { UsersService } from '@services/users.service';
import { Brand } from '@interfaces/brand';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {

  public submit = false;
  private counterBrands = 1;
  public client: Array<any> = [];
  public formCreateClient: FormGroup;
  public filteredOptions: Observable<any[]>;
  public formIdsBrands: Array<string> = ['brand_0'];

  constructor(
    public dialogRef: MatDialogRef<CreateClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientService: ClientService,
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.formCreateClient = new FormGroup({
      name: new FormControl('', Validators.required),
      brand_0: new FormControl('', Validators.required),
      payment_conditions: new FormControl({ value: '', disabled: true }),
      picture: new FormControl({ value: '', disabled: true })
    });
    this.getClient();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public addNewBrand() {
    this.formCreateClient.addControl('brand_' + this.counterBrands, new FormControl('', Validators.required));
    this.formIdsBrands.push('brand_' + this.counterBrands++);
  }

  public removeNewBrand(id) {
    if (this.formIdsBrands.length <= 1) { return; }
    const position = this.formIdsBrands.indexOf(id);
    this.formCreateClient.removeControl(id);
    this.formIdsBrands.splice(position, 1);
  }

  async getClient() {
    try {
      this.client = await this.clientService.getClients();
    } catch (e) {
      console.error(e);
    }
    this.filteredOptions = this.formCreateClient.get('name').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }
  public closedClient() {
    const client = this.formCreateClient.get('name').value;
    if (typeof (client) !== 'object') { return; }
    this.formCreateClient.get('picture').setValue('');
    this.formCreateClient.get('picture').disable();
    this.formCreateClient.get('payment_conditions').setValue(client.payment_conditions);
    this.formCreateClient.get('payment_conditions').disable();
  }
  public blurClient() {
    const client = this.formCreateClient.get('name').value;
    if (typeof (client) === 'object' || client === '') { return; }
    this.formCreateClient.get('picture').enable();
    this.formCreateClient.get('payment_conditions').enable();
  }
  async createBrand() {
    this.submit = true;
    const data = {};
    const brands: Array<Brand> = [];
    if(this.formCreateClient.invalid) { return; }
    for (const i of this.formIdsBrands) {
      brands.push({ name: this.formCreateClient.get(i).value });
    }
    data['brands'] = JSON.stringify(brands);
    // tslint:disable-next-line: forin
    for (const prop in this.formCreateClient.value) {
      if (prop === 'brand_0') { continue; }
      if (prop === 'name') {
        const name = this.formCreateClient.get('name').value;
        data[prop] = typeof (name) === 'object' ? name.name : name;
        continue;
      }
      data[prop] = this.formCreateClient.get(prop).value;
    }
    let response;

    const clientID = this.formCreateClient.get('name').value;
    try {
      if (typeof (clientID) === 'object') {
        response = await this.usersService.postBrands(clientID.id, brands); // Array de respuestas de promesas
        this.clientService.brand[clientID.id] = null;
        this.clientService.client = [];
        this.dialogRef.close({
          update: true,
          action: 1,
          id: clientID.id,
          brands: clientID.brands,
          search: 'id'
        });
      } else {
        response = await this.usersService.postClient(data);
        this.clientService.client = [];
        this.dialogRef.close({
          update: true,
          action: 0,
          clientName: clientID,
          search: 'name'
        });
      }
    } catch (e) {
    }
  }

  public displayName(user?: any): string | undefined {
    return user ? user.name : undefined;
  }
  private _filter(value: any) {
    const filterValue = typeof (value) === 'object' ? value.name.toLowerCase() : value.toLowerCase();
    return this.client.filter((option) =>
      option.name.toLowerCase().includes(filterValue));
  }
  public onlyNumbers(ev, float: boolean = false) {
    if (ev.keyCode === 189 || ev.keyCode === 187 || ev.keyCode === 69 || ev.keyCode === 229 ||
      (float && ev.keyCode === 190)) {
      ev.preventDefault();
      return;
    }
  }
}
