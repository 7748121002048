import { Component, OnInit, OnChanges } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ClientService } from '@services/client.service';
import { UsersService } from '@services/users.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnChanges {

  public user: any;
  public userData: any;

  constructor(
    public auth: AuthService,
    public client: ClientService,
    public userService: UsersService
  ) {
  }

  ngOnInit() {
    this.userData = this.client.getInfoUser();
    this.getInfoUser(this.userData.id);
  }

  ngOnChanges() {

  }


  getInfoUser(id) {
    this.userService.getUserElemento(id).then(
      data => {
        this.user = data['user'];
      }
    );
  }
}
