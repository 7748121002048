import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styles: []
})

export class ProtectedComponent implements OnInit {

  responseJson: string;

  constructor(public auth: AuthService, private api: ApiService) { }

  ngOnInit() {

  }

  pingApi() {
    this.api.ping$().then(
      res => (
        this.responseJson = res
      )
    );
  }

}
