import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from '@guards/auth.guard';
import { InterceptorService } from '@services/interceptors/interceptor.service';
import { SupplierInterceptorService } from '@services/interceptors/supplier-interceptor.service';
import { LoginComponent } from '../app/pages/supplier/login/login.component';
import { CreatePasswordComponent } from '../app/pages/supplier/create-password/create-password.component';
import { SupplierRegistrationComponent } from '../app/pages/supplier/supplier-registration/supplier-registration.component';

const routesTwo: Routes = [
  {
    path: 'supplier',
    component: LoginComponent
  },
  {
    path: 'set-password',
    component: CreatePasswordComponent,
  },
  {
    path: 'provider-profile',
    component: SupplierRegistrationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routesTwo)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SupplierInterceptorService,
      multi: true
    }
  ]
})
export class RoutingSupplierModule { }
