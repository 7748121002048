import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '@services/users.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
  styleUrls: ['../create-client/create-client.component.scss', './confirmation-delete.component.scss']
})
export class ConfirmationDeleteComponent implements OnInit {

  public myGroup: FormGroup;
  public submit = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public usersService: UsersService,
    private router: Router
  ) {

  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteProject() {
    switch (this.data.type) {
      case 'project':
        this.usersService.deleteProject(this.data.data.id).then(
          data => {
            this.dialogRef.close();
          }
        );
        break;
      case 'client':
        this.usersService.deleteClient(this.data.data.id).then(
          data => {
            this.dialogRef.close();
          }
        );
        break;
      case 'elemento':
        this.usersService.deleteElemento(this.data.data.id).then(
          data => {
            this.dialogRef.close();
          }
        );
        break;
      default:
        break;
    }
  }

}
