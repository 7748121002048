import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SupplierService } from '@services/supplier.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public loginForm: FormGroup;
  public submitted = false;

  constructor(
    private fb: FormBuilder,
    private supplierService: SupplierService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(10),
        Validators.maxLength(100)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16)
      ])
    });
  }


  public login() {
    this.submitted = true;
    if (this.loginForm.invalid) { return; }
    this.supplierService.loginSupplier(this.loginForm.value).then(data => {
      this.supplierService.saveAuthorization(data);
      const previousRoute = localStorage.getItem('ROUTE-SESSION');
      if (previousRoute) {
        this.router.navigate([previousRoute]);
      } else {
        this.router.navigate(['/provider-profile']);
      }
    });
  }
}
