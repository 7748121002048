import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UsersService } from '@services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  public messageForm: FormGroup;
  public isSubmitted = false;

  constructor(
    private fb: FormBuilder,
    public usersService: UsersService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.messageForm = this.fb.group({
      message: ['', Validators.required]
    });
  }


  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close({ data: 'guardad' });
  }


  openSnackBar(message?: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  sendFormProvider() {
    this.isSubmitted = true;
    if (this.messageForm.valid) {
      const dataSend = {
        expense_id: this.data.provider_id,
        message: this.messageForm.value.message,
        status: this.data.statusProvider
      };
      this.usersService.sendStatusProvider(dataSend).subscribe(
        (response: any) => {
          this.openSnackBar('Mensaje enviado');
          this.dialogRef.close({ data: 'guardado' });
        }
      );
    }
  }

}
