import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['../create-client/create-client.component.scss', './new-project.component.scss']
})
export class NewProjectComponent implements OnInit {

  public myGroup: FormGroup;
  public submit = false;

  constructor(
    public dialogRef: MatDialogRef<NewProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.myGroup = new FormGroup({
      name: new FormControl('', Validators.required)
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public createProject() {
    this.submit = true;
    if (this.myGroup.get('name').invalid) { return; }
    this.usersService.postProject(this.myGroup.value, this.data.brand.id).then(() => {
      this.dialogRef.close(true);
    }, (error) => {
    });
  }
}
