import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UsersService } from '@services/users.service';
import { ClientService } from '@services/client.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public userData: any;
  public user: any;

  constructor(
    private router: Router,
    public userService: UsersService,
    public client: ClientService,
    private location: Location
  ) {
    router.events
      .pipe(filter((routerEvent: Event) => routerEvent instanceof NavigationEnd))
      .subscribe(() => document.getElementById('content').scrollTo(0, 0));
  }

  ngOnInit() {
    this.userData = this.client.getInfoUser();
    if (this.userData) {
      this.getInfoUser(this.userData.id);
    } else {
      const tmpRoute = this.location.path();
      localStorage.setItem('ROUTE-SESSION', tmpRoute);
      this.router.navigate(['/login']);
    }
  }

  getInfoUser(id) {
    this.userService.getUserElemento(id).then(
      data => {
        this.user = data['user'];
      }
    );
  }
}
