import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, apiVersion } from '@env/environment';



@Injectable({
  providedIn: 'root'
})
export class ClientService {

  public brand = {};
  public project = {};
  public client: Array<any>;
  public suppliers: Array<any>;
  public user: any;

  constructor(private http: HttpClient) { }

  getInfoUser() {
    return JSON.parse(localStorage.getItem('user_element'));
  }

  async reloadClients(): Promise<any> {
    this.client = [];
    return this.getClients();
  }

  async getClients(): Promise<any> {
    if (!this.client || this.client.length === 0) {
      this.client =
        await this.http.get(environment.server + environment.clients).toPromise().then((response: any) => response.customers);
    }
    return this.client;
  }

  async getBrands(id, urlBrands): Promise<any> {
    if (!this.brand[id]) {
      this.brand[id] =
        await this.http.get(environment.server + apiVersion + urlBrands).toPromise().then((response: any) => response.brands);
    }
    return this.brand[id];
  }

  async getProjects(id, urlProject): Promise<any> {
    if (!this.project[id]) {
      this.project[id] =
        await this.http.get(environment.server + urlProject).toPromise().then((response: any) => response.projects);
    }
    return this.project[id];
  }

  getAllProjects() {
    return this.http.get(environment.server + environment.projects + '?friendly=true').toPromise();
  }

  getBussinessActivities() {
    return this.http.get(environment.server + environment.business_activities);
  }

  async getSuppliers() {
    if (!this.suppliers || this.suppliers.length === 0) {
      this.suppliers =
        await this.http.get(environment.server + environment.suppliers).toPromise().then((response: any) => response.suppliers);
    }
    return this.suppliers;
  }

  getSupplier(id) {
    return this.http.get(environment.server + environment.suppliers + '/' + id).toPromise();
  }

  async getConcepts(): Promise<any> {
    return this.http.get(environment.server + environment.concepts).toPromise().then((response: any) => response.concepts);
  }

  getProfitability(): Promise<any> {
    return this.http.get(environment.server + environment.profitability).toPromise();
  }

  getElementos(): Promise<any> {
    return this.http.get(environment.server + environment.elementos).toPromise();
  }

  getBrandsByClient(brands): Promise<any> {
    return this.http.get(environment.server + apiVersion + brands).toPromise();
  }

}
