import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-new-provider',
  templateUrl: './new-provider.component.html',
  styleUrls: ['../create-client/create-client.component.scss', './new-provider.component.scss']
})
export class NewProviderComponent implements OnInit {

  public submit = false;
  private allActivities = [];
  public formCreateProvider: FormGroup;
  public filteredOptionsActivities: Observable<any[]>;

  constructor(
    public dialogRef: MatDialogRef<NewProviderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService
  ) { }

  ngOnInit() {

    this.formCreateProvider = new FormGroup({
      company_name: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(/^[A-Za-zÁÉÍÓÚÜÑáéíóúüñ][A-Za-zÁÉÍÓÚÜÑáéíóúüñ\-. ]*$/)
      ])),
      contact_name: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(/^[A-Za-zÁÉÍÓÚÜÑáéíóúüñ][A-Za-zÁÉÍÓÚÜÑáéíóúüñ\-. ]*$/)
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(12),
        Validators.pattern(/^\(?\+?[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}$/)
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(100),
        Validators.email
      ])),
      business_activity: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    this.usersService.getbusinessActivities().then(response => {
      this.allActivities = response['business_activities'];
      this.filteredOptionsActivities = this.formCreateProvider.get('business_activity').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, this.allActivities, 'name'))
      );
    });
  }

  public createProvider() {
    this.submit = true;
    if (this.formCreateProvider.invalid) { return; }
    this.usersService.postSuppliers(this.formCreateProvider.value).then(response => {
      this.dialogRef.close({
        refresh: true,
        id: this.data.id
      });
    });
  }
  private _filter(value: any, arrayToSearch: Array<any>, attr: string) {
    const filterValue = typeof (value) === 'object' ? value[attr].toLowerCase() : value.toLowerCase();
    return arrayToSearch.filter((option) =>
      option[attr].toLowerCase().includes(filterValue));
  }
}
