import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from '@guards/auth.guard';
import { InterceptorService } from '@services/interceptors/interceptor.service';
import { LoaderInterceptorService } from '@services/interceptors/loader-interceptor.service';
import { ErrorInterceptorService } from '@services/interceptors/error-interceptor.service';
import { LayoutComponent } from '@components/layout/layout.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: './pages/home/home.module#HomeModule'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'my-profile',
        loadChildren: './pages/user-profile/user-profile.module#UserProfileModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'applications',
        loadChildren: './pages/applications/applications.module#ApplicationsModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'elementos',
        loadChildren: './pages/elementos/elementos.module#ElementosModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: './pages/applications-admin/applications-admin.module#ApplicationsAdminModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'rh',
        loadChildren: './pages/applications-rh/applications-rh.module#ApplicationsRhModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'pay',
        loadChildren: './pages/applications-pay/applications-pay.module#ApplicationsPayModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'applications-project',
        loadChildren: './pages/applications-by-project/applications-by-project.module#ApplicationsByProjectModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'applications-to-approve',
        loadChildren: './pages/applications-to-approve/applications-to-approve.module#ApplicationsToApproveModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'formats/external-suppliers-budget',
        loadChildren: './pages/formats/budget/budget.module#BudgetModule',
        canActivate: [AuthGuard]
        // pathMatch: 'full'
      },
      {
        path: 'formats/refounds',
        loadChildren: './pages/formats/internal-spending/internal-spending.module#InternalSpendingModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'formats/internal-spending',
        loadChildren: './pages/formats/internal-spending/internal-spending.module#InternalSpendingModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'formats/direct_expense',
        loadChildren: './pages/formats/direct_expense/internal-spending.module#InternalSpendingModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'formats',
        pathMatch: 'full',
        redirectTo: 'formats/external-suppliers-budget'
      },
      {
        path: 'requests-sent/:id',
        loadChildren: './pages/requests-sent/requests-sent.module#RequestsSentModule',
      },
      {
        path: 'clients',
        loadChildren: './pages/clients/clients.module#ClientsModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'drafts',
        loadChildren: './pages/drafts/drafts.module#DraftsModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'calendar',
        loadChildren: './pages/calendar/calendar.module#CalendarPageModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'profitability',
        loadChildren: './pages/profitability/profitability.module#ProfitabilityModule',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'edit-project/:id',
        loadChildren: './pages/edit-project/edit-project.module#EditProjectModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'edit-client/:id',
        loadChildren: './pages/edit-client/edit-client.module#EditClientModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'edit-elemento/:id',
        loadChildren: './pages/edit-elemento/edit-elemento.module#EditElementoModule',
        canActivate: [AuthGuard],
      },
      { path: '**', redirectTo: '' }
    ]
  },
  // {
  //   path: 'supplier',
  //   loadChildren: './pages/supplier/supplier.module#SupplierModule'
  // },
  // {
  //   path: 'protected',
  //   component: ProtectedComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: '**', pathMatch: 'full', redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    }
  ]
})
export class AppRoutingModule { }
