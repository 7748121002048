import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-departure',
  templateUrl: './confirm-departure.component.html',
  styleUrls: ['../create-client/create-client.component.scss', '../confirmation-delete/confirmation-delete.component.scss',
              './confirm-departure.component.scss']
})
export class ConfirmDepartureComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDepartureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  public exit(option) {
    this.dialogRef.close(option);
  }
}
