import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { SupplierRegistrationComponent } from './supplier-registration/supplier-registration.component';
import { LoginComponent } from './login/login.component';
import { MatInputModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { InputFilesModule } from '@components/input-files/input-files.module';
import { RoutingSupplierModule } from '../../routing-supplier.module';

@NgModule({
  declarations: [
    CreatePasswordComponent,
    SupplierRegistrationComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    InputFilesModule,
    FormsModule,
    RoutingSupplierModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SupplierModule { }
