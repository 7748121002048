import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SupplierService } from '@services/supplier.service';

@Component({
  selector: 'app-supplier-registration',
  templateUrl: './supplier-registration.component.html',
  styleUrls: ['./supplier-registration.component.scss']
})
export class SupplierRegistrationComponent implements OnInit {

  public stepCompleted = [false, false];
  public contactInformation: FormGroup;
  public invoiceForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private supplierService: SupplierService
  ) { }

  ngOnInit() {
    this.contactInformation = this.fb.group({
      contact_name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern(/^\d*$/)
      ]),
      email: new FormControl(''),
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      rfc: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
      ]),
      business_name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      banking_institution: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      bank_account: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
      ]),
      account_status_file: new FormControl('', [
        Validators.required
      ]),
    });
    this.invoiceForm = this.fb.group({
    });
    this.supplierService.getProviderData().then(data => {
      this.contactInformation.get('contact_name').setValue(data.contact_name);
      this.contactInformation.get('phone').setValue(data.phone);
      this.contactInformation.get('email').setValue(data.email);
    });
  }

  public updateContactInformation() {
  }
}
