import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { IconsService } from '@services/icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private iconsService: IconsService
  ) { }

  ngOnInit() {
    this.auth.localAuthSetup();
    this.iconsService.registerIcons();
  }
}

