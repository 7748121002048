import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { UsersService } from '@services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-information',
  templateUrl: './confirm-information.component.html',
  styleUrls: [
    '../create-client/create-client.component.scss',
    './confirm-information.component.scss'
  ]
})
export class ConfirmInformationComponent implements OnInit {
  public myGroup: FormGroup;
  public submit = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public usersService: UsersService,
    private router: Router
  ) {}

  ngOnInit() {
    this.myGroup = new FormGroup({
      message: new FormControl('', Validators.required)
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmationSend() {
    this.submit = true;
    if (this.myGroup.invalid) {
      return;
    }
    this.usersService
      .confirmInformation(this.myGroup.value, this.data.id, this.data.type)
      .then(response => {
        this.usersService.finishBudget = true;
        this.router.navigate(['/applications']);
        this.dialogRef.close();
      });
  }
}
