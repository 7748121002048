export const SERVER_URL = 'https://paydevserver.element.mx';
export const SERVER_URL_LOGIN = 'https://payment-client.element.mx/';
export const SERVER_URL_REDIRECT = 'http://localhost:4200/';
export const apiVersion = '/api/v1/';
export const environment = {
  production: false,
  domain: 'elementpayment.auth0.com',
  client_id: 'i7RJiiFkMzWcgC8LQY4EKqra5B63PXLO',
  audience: 'https://elementpayment.auth0.com/api/v2/',
  // redirect_uri: `https://payment-client.element.mx/`,
  redirect_uri: `https://paymentest.element.mx`,
  login_url: `https://payment-client.element.mx/`,
  // server: 'https://payments-server.element.mx',
  server: 'https://paymentest-server.element.mx',
  scope: 'read:current_user',
  // Servicios
  // Global
  concepts: `${apiVersion}catalogs/concepts`,
  business_activities: `${apiVersion}catalogs/business_activities`,
  // Eser
  logiSuppliers: `${apiVersion}suppliers/session`,
  updateUserProfile: userId => `${apiVersion}users/${userId}`,
  // Clients
  clients: `${apiVersion}customers`,
  post_clients: `${apiVersion}customers/brands`,
  // Brands
  brands: `${apiVersion}brands`,
  create_brands: customerId => `${apiVersion}customers/${customerId}/brands`,
  // Projects
  projects: `${apiVersion}projects`,
  post_project: customerId => `${apiVersion}brands/${customerId}/projects`,
  // Create Direct Expense
  quotations_directExpense: customerId => `${apiVersion}projects/${customerId}/quotationssimple`,
  // Create budget
  suppliers: `${apiVersion}suppliers`,
  quotations_s1: customerId => `${apiVersion}projects/${customerId}/quotations`,
  update_quotations_s1: quotationId => `${apiVersion}quotations/${quotationId}`,
  quotations_s2: customerId =>
    `${apiVersion}quotations/${customerId}/suppliers`,
  update_quotations_s2: (customerId, supplierId) =>
    `${apiVersion}quotations/${customerId}/suppliers/${supplierId}`,
  quotations_s3: customerId => `${apiVersion}quotations/${customerId}/expenses`,
  update_quotations_s3: (customerId, expenseId) =>
    `${apiVersion}quotations/${customerId}/expenses/${expenseId}`,
  quotations_s4: customerId => `${apiVersion}quotations/${customerId}/resume`,
  postQuotations_s4: customerId =>
    `${apiVersion}quotations/${customerId}/confirm`,
  updateQuotation: customerId => `${apiVersion}quotations/${customerId}`,
  updatePriority: customerId => `${apiVersion}quotations/${customerId}/priority`,
  rejectQuotation: customerId => `${apiVersion}quotations/${customerId}/reject`,
  allQuotations: `${apiVersion}quotations`,
  // Create internal spending
  post_expenses: `${apiVersion}supplies/expenses`,
  put_expenses: (supplyId, expenseId) =>
    `${apiVersion}supplies/${supplyId}/expenses/${expenseId}`,
  delete_expenses: (supplyId, expenseId) =>
    `${apiVersion}supplies/${supplyId}/expenses/${expenseId}`,
  resumen_expenses: supplyId => `${apiVersion}supplies/${supplyId}`,
  confirm_expenses: supplyId => `${apiVersion}supplies/${supplyId}/confirm`,
  // Create refound
  post_expenses_refound: `${apiVersion}refunds/expenses`,
  put_expenses_refound: (refundId, expenseId) =>
    `${apiVersion}refunds/${refundId}/expenses/${expenseId}`,
  delete_expenses_refound: (refundId, expenseId) =>
    `${apiVersion}refunds/${refundId}/expenses/${expenseId}`,
  resumen_expenses_refound: refundId => `${apiVersion}refunds/${refundId}`,
  confirm_expenses_refound: refundId => `${apiVersion}refunds/${refundId}/confirm`,
  // Calendar
  calendar: `${apiVersion}schedules`,
  // Profitability
  profitability: `${apiVersion}reports/profitability`,
  // Elementos
  elementos: `${apiVersion}users`,
  // Admin
  // admin: `${apiVersion}projects/21/quotations-admins`
  admin: `${apiVersion}quotations`,
  // Send rh review
  rh: `${apiVersion}quotations/paidquotation`,
  // Send provider review
  provider: `${apiVersion}quotations/suppliers/addpaid`,
  // Comments
  comments: `${apiVersion}quotations/{quotation_id}/addcomment`,
  // Status provider
  status: `${apiVersion}quotations/expencestatus`
};
