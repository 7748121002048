import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplierInterceptorService implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          err => {
            observer.error(err);
          },
          () => {
            observer.complete();
          });
      return () => {
        subscription.unsubscribe();
      };
    });
  }
}
