import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-files',
  templateUrl: './input-files.component.html',
  styleUrls: ['./input-files.component.scss']
})
export class InputFilesComponent implements OnInit {

  @Input() nameFile = '';
  @Input() error: boolean;
  @Input() formName: string;
  @Input() formGroup: FormGroup;
  @Input() maxSize: number; // 5;
  @Input() extension: string; // '.jpg, .pdf, .xls, .png';
  @Input() placeHolder: string; // 'Adjuntar cotización Element';
  @Input() disabled: boolean;
  @Output() fileSelected = new EventEmitter();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event.item(0);
    if (!file || this.maxSize * 1024000 <= file.size) {
      if (this.nameFile === '') {
        this.error = true;
      }
      return;
    }
    this.nameFile = file.name;
    this.error = false;
    const newValueFile = {};
    newValueFile[this.formName] = file;
    this.formGroup.patchValue(newValueFile);
    this.fileSelected.emit(true);
  }

  constructor() { }

  ngOnInit() {
    this.formGroup.get(this.formName).valueChanges.subscribe(val => {
      if (typeof(val) === 'object') {
        this.nameFile = val.name;
      }
      if (val === '') {
        this.nameFile = '';
      }
    });
  }

}
